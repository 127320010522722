import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col } from 'react-bootstrap';
import skillsIcon1 from '../skills-icon.svg';
import skillsIcon2 from '../skillsIcon2.svg';
import skillsIcon3 from '../skillsIcon3.svg';
import skillsIcon4 from '../skillsIcon4.svg';

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx">
                            <h2>Skills</h2>
                            <p>Here are some of the skills I have acquired throughout my career as a Software Developer.</p>
                            <Carousel responsive={responsive} infinite={true} className="skill-slider">
                                <div className="item">
                                    <img src={skillsIcon2} alt = "item image" />
                                    <h6>Web Development</h6>
                                </div>
                                <div className="item">
                                    <img src={skillsIcon1} alt = "item image" />
                                    <h6>Software Development</h6>
                                </div>
                                <div className="item">
                                    <img src={skillsIcon3} alt = "item image" />
                                    <h6>UX/UI Design</h6>
                                </div>
                                <div className="item">
                                    <img src={skillsIcon4} alt = "item image" />
                                    <h6>Libraries and Frameworks</h6>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
import { useEffect, useState } from "react";
import {Navbar, Container, Nav} from "react-bootstrap";
import navIcon1 from '../navIcon1.svg';
import navIcon2 from '../navIcon2.svg';
import navIcon3 from '../navIcon3.svg';
import myLogo1 from '../myLogo1.svg';

export const NavBar = () => {

    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false); /* holds info on whether user has scrolled*/

    useEffect(() => {
        const onScroll = () => { /* sets whether user scrolled and adds event handler*/
            if(window.scrollY > 50) {
               setScrolled(true); 
            }
            else {
                setScrolled(false);
            }
        }
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []) /* only once */

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
    }

   return (
   <Navbar expand="lg" className={scrolled ? "scrolled": ""}>
   <Container>
    <Navbar.Brand href="#home">
      <img src={myLogo1} alt="logo"/>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav">
        <span className="navbar-toggler-icon"></span> 
    </Navbar.Toggle>
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>

        <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>

        <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
      </Nav>
       <span className="navbar-text">
        <div className="social-icon">
          <a href="https://www.linkedin.com/in/christopher-pereira-8418a8219" target="_blank"><img src={navIcon1} alt="" /></a>
          <a href="https://www.facebook.com/profile.php?id=100028576715250" target="
          _blank"><img src={navIcon2} alt="" /></a>
          <a href="#"><img src={navIcon3} alt="" /></a>
        </div>
        <button className="vvd" onClick={() => console.log('connect')}><span> Connect with Me</span></button>
       </span>
     </Navbar.Collapse>
   </Container>
 </Navbar>
 ); 
}